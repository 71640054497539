import consumer from "./consumer"

if (window.ImportChannel == null) {
  window.ImportChannel = {}
}

window.ImportChannel.initializer = function(place, sid) {
  window.ImportChannel[sid] = consumer.subscriptions.create(
    {
      channel: "ImportChannel",
      sid: sid
    },
    {
      // Вызывается единожды при создании подписки.
      initialized() {
        this.place = place
        this.redom(place)
      },
      redom(place) {
        this.progress = place.find(">.card-body .progress")
        this.progress_bar = place.find(">.card-body .progress-bar")
        this.steps = place.find(">.card-body .steps")
        this.start = place.find(">.card-body .start")
        this.finish = place.find(">.card-body .finish")
        this.status = place.find(">.card-body .status")
        this.notices = place.find(">.card-body .notices")
      },
      // Вызывается, когда получены данные по каналу.
      received(data) {
        // console.log('data: ', data)
        if (data.progress != null) {
          this.progress_bar.css("width", `${data.progress}%`)
          this.progress_bar.text(`${data.progress}%`)
        }
        if (data.steps != null) {
          this.steps.html(data.steps)
        }
        if (data.progress_bar != null) {
          this.progress.html(data.progress_bar)
          this.redom(this.place)
        }
        if (data.status != null) {
          this.status.html(data.status)
        }
        if (data.start != null) {
          this.start.text(data.start)
        }
        if (data.finish != null) {
          this.finish.text(data.finish)
        }
        if (data.notice != null) {
          this.notices.append(data.notice)
        }
      }
    }
  )
}

$(document).on('turbolinks:load', function() {
  var import_services = $(".import_service")
  if (import_services.length > 0) {
    import_services.each(function() {
      var import_service = $(this)
      var sid = import_service.data("sid")
      if (sid == null) {
        return null
      }
      if (window.ImportChannel[sid] == null) {
        window.ImportChannel.initializer(import_service, sid)
      } else {
        window.ImportChannel[sid].place = import_service
        window.ImportChannel[sid].redom(import_service)
      }
    })
  }
})
